import React, { useEffect } from 'react'

export default function Login() {

    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [error, setError] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        if (localStorage.getItem('token')) {
            window.location.href = '/home';
        }
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setLoading(true);

        try {
            const response = await fetch('https://amara-ws-w8ww4.ondigitalocean.app/users/validatelogin', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    param1: username,
                    param2: password,
                    param3: 'Admin'

                })
            });

            const data = await response.json();
            console.log(JSON.stringify(data));
            if (data.statusCode === '200') {
                sessionStorage.setItem('token', data.data);
                window.location.href = '/home';
            } else {
                setError(data.message);
            }
        } catch (error) {
            setError(error.message);
        }

        setLoading(false);
    }

    return (
        <div className='row' style={{ width: '100%', margin: 'auto', alignContent: 'center' }}>
            <div className='col-md-4'></div>

            <div className='col-md-4' style={{ marginTop: 150 }}>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Username</label>
                        <input type="text" className="form-control" value={username} onChange={(e) => setUsername(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label>Password</label>
                        <input type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} />
                    </div>
                    <button type="submit" className="btn btn-primary" disabled={loading}>Login</button>
                    {error && <div className="alert alert-danger" role="alert">{error}</div>}
                </form>
            </div>
            <div className='col-md-4'></div>
        </div>
    )
}
