import React from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { Link, useNavigate } from 'react-router-dom';

export default function Users() {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [visible, setVisible] = useState(false);
    const toggleSidebar = () => setVisible(!visible);
    const navigation = useNavigate();

    useEffect(() => {
        if (users.length === 0) {
            console.log(JSON.stringify(sessionStorage.getItem('token')));
            fetch('https://amara-ws-w8ww4.ondigitalocean.app/users', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }

            })
                .then(res => res.json())
                .then(data => {
                    console.log('Response:', JSON.stringify(data));
                    setUsers(data.data);
                    setLoading(false);
                    // setTotalRecords(data.length);
                })
        }
    });
    return (
        <div className='row'>
            <div className='col-md-11'>
                <h1>Users</h1>
            </div>
            <div className='col-md-1'><Button label="Add" icon="pi pi-plus" onClick={(e) => setVisible(true)} /></div>
            <div className='col-md-12'>
                <div className="card">
                    <DataTable value={users} responsiveLayout="scroll" paginator rows={10} rowsPerPageOptions={[10, 25, 50]} tableStyle={{ minWidth: '50rem' }}>
                        <Column field="fullName" header="Name"></Column>
                        <Column field="username" header="Username"></Column>
                        <Column field="userTypeId.userTypeName" header="User Type"></Column>
                        <Column header="Actions">
                            <Button label="Edit" icon="pi pi-pencil" className="p-button-success" />
                        </Column>
                    </DataTable>
                </div>
            </div>
            <div className='col-md-6'>
                <Sidebar visible={visible} position="right" onHide={() => setVisible(false)}>
                    Content
                </Sidebar>
            </div>
        </div>
    )
}
