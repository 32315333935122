import './App.css';
import Header from './components/Header';
import { BrowserRouter, Routes } from "react-router-dom";
import { Route } from "react-router-dom";
import Home from './components/Home';
import Departments from './components/Departments';
import Batches from './components/Batches';
import NoPageFound from './components/NoPageFound';
import Companies from './components/Companies';
import Login from './components/Login';
import Users from './components/Users';
import States from './components/States';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/home" element={<Home />} />
          <Route path="/states" element={<States />} />
          <Route path="/users" element={<Users />} />
          <Route path="*" element={<NoPageFound />} />
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default App;
