import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

export default function Header() {
    const navigation = useNavigate();
    const [token, setToken] = useState(sessionStorage.getItem('token'));
    const logout = () => {
        sessionStorage.removeItem('token');
        navigation('/');
        window.location.reload();
    }

    return (
        <>
            {token ? (
                <nav className="navbar navbar-expand-lg navbar-light bg-light" >
                    <a className="navbar-brand" href="/">AmaraLands</a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav mr-auto">

                            <li className="nav-item">
                                <Link className="nav-link" to='/states'>States</Link>
                            </li>
                            {/* <li className="nav-item">
                                <Link className="nav-link" to='/batches'>Batches</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to='/companies'>Companies</Link>
                            </li> */}
                            <li className="nav-item">
                                <Link className="nav-link" to='/users'>Users</Link>
                            </li>

                            {/* <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Dropdown
                        </a>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <a className="dropdown-item" href="#">Action</a>
                            <a className="dropdown-item" href="#">Another action</a>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item" href="#">Something else here</a>
                        </div>
                    </li> */}
                            {/* <li className="nav-item">
                        <a className="nav-link disabled" href="#">Disabled</a>
                    </li> */}
                        </ul>
                        <div className="form-inline my-2 my-lg-0">

                            <button className="btn btn-outline-success my-2 my-sm-0" onClick={logout} >Logout</button>
                        </div>
                    </div>
                </nav>) : ''
            }
        </>
    )
}
